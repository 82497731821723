<template>        
    <span class="link-button text-primary" :class="{'disabled': disabled}"  @click="onClick">
        <span class="mr-2" v-if="compIcon">
            <Icon color="compColor" small class="btn-icon mr-2" :type="compIcon"></Icon>
        </span>
        <slot>{{compText}}</slot>
    </span>

</template>

<script>
import navigation from '@app/navigation'
import Icon from '@shared/ui/controls/icons/Icon'

//
// Action buttons are buttons where the action is defined in the navigation component.
// The navigation component maintains a list of actions and can execute those. 
// Also, the navigation component is authorization aware and we use it to determine whether 
// an action can be executed by this user. The advantage is that all metadata is in navigation.js and 
// not scattered throughout the client application. 
// Note that of course, on the server, also authorization is checked. 
//
//
// Usage: 
//
//   import ActionLinkButton from '@controls/buttons/ActionLinkButton'
//   <ActionLinkButton action='tariff.create'></ActionLinkButton>
//
export default {
    name: 'ActionLinkButton',
    components: {Icon},
    props: {
        action: {
            type: [String],
            default: null
        },     
        count: {
            type: [String, Number]
        },
        disabled: {
            type: [Boolean],
            default: false
        },
        // color and icon are optional. 
        // For 'standard' types, colors and icons are predefined and can be overruled by specifying color and / or icon property.
        color: {
            type: [String],
            default: "primary"
        },
        icon: {
            type: [String]                
        },
        noIcon: {
            type: [Boolean],
            default: false
        },
        loading: {
            type: [Boolean],
            default: false
        },
        // When true, the specified action is only used for authorization.
        // Executing the action is done by the caller by handling the 'click' event.
        noExecute: {
            type: [Boolean],
            default: false
        },

        payload: {
            type: [Object, Array, String, Number],
        },
    },
    data () {
        return {             
            actionItem: {},
        }
    },

    methods: {
        onClick() {
            if (this.disabled) {
                return; // Do nothing.
            }
            if (!this.action || this.noExecute) {
                this.$emit('click');
                return;
            }
            
            if (navigation.executeAction(this.action, this.payload)) {
                this.$emit('click')
            }
        },
    },    

    computed: {
        compColor: function() {
            return this.color;
        },
        cls: function() {
            let disabled = this.compDisabled ? "disabled":"";
            return `link-button ${disabled}`;
        },
        compDisabled: function() {
            if (this.disabled) {
                return true;
            }
            return !this.actionItem.isAuthorized;
        },
        compIcon: function() {
            if (this.noIcon) {
                return null;
            }
            let ico = this.icon;
            if (!ico && this.actionItem) {
                ico = this.actionItem.type;
            }
            return ico;
        },

        compText:function() {
            return this.actionItem.text || '-';
        }   
    },

    mounted() {
        if (this.action) {            
            this.actionItem = navigation.getAction(this.action) || {};
        }
        else {
            // Normal button, always authorized.
            this.actionItem = {isAuthorized: true}
        }

    },

}

</script>
