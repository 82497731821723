<template>    
    <v-row dense> 
        <v-col :class="$slots.right?'pr-2':''" :sm="compLeftSM" :lg="compLeftLG">
            <slot></slot>
        </v-col>
        <v-col class="pl-2" v-if="$slots.right" :sm="compRightSM" :lg="compRightLG">            
            <slot name="right"></slot>
        </v-col>
    </v-row>

</template>

<script>

//
// Usage: 
//
//   import Form from '@controls/forms/Form'
//   import FormRow from '@controls/forms/FormRow'
//
//  Example: 1 column
//   <v-form ref="product">
//      <Form>
//             <FormRow label="ArtikelCode">
//                 <TextField :rules="model.rules.pd_code" v-model="model.pd_code" help="product.pd_code"></TextField>
//             </FormRow>
//             <FormRow label="ArtikelNaam">
//                 <TextField :rules="model.rules.pd_name" v-model="model.pd_name" help="product.pd_name"></TextField>
//             </FormRow>
//      </Form>
//  </v-form>
//
// Example: 2 columns. Each width 6 in grid of 12, for small screens, full width:
// ----------------------------------------------------------------------------------------------------------------  
//   <v-form ref="product">
//       <Form sm="12-12" lg="6-6">
//          <FormRow label="ArtikelCode">
//              <TextField :rules="model.rules.pd_code" v-model="model.pd_code" help="product.pd_code"></TextField>
//          </FormRow>
//          <FormRow label="ArtikelNaam">
//              <TextField :rules="model.rules.pd_name" v-model="model.pd_name" help="product.pd_name"></TextField>
//          </FormRow>
//
//          <template v-slot:right>
//              <FormRow label="BTW">
//                  <TextField :rules="model.rules.pd_vat" v-model="model.pd_vat" help="product.pd_vat"></TextField>
//              </FormRow>
//              <FormRow label="Opmerking">
//                  <TextField :rules="model.rules.pd_remark" v-model="model.pd_remark" help="product.pd_remark"></TextField>
//              </FormRow>
//      </Form>
//  </v-form>
//
export default {
    name: 'Form',
    components: {},
    props: {
        sm: {
            type: [String],
            default: null
        },
        lg: {
            type: [String],
            default: null
        },
    },
    methods: {
        widthFromString(str, index, defaultValue) {
            var arr = (str||"").split("-");
            if (arr && arr.length >= index) {
                return arr[index] || defaultValue;
            }
            return defaultValue;
        }
    },
    computed: {

        compLeftSM: function() {
            return this.widthFromString(this.sm||"12-12", 0, "12")
        },
        compLeftLG: function() {
            var lg = (this.lg) ? this.lg : (this.$slots['right']  ? "6-6":"12-12" )
            return this.widthFromString(lg, 0, this.$slots['right'] ? "6" : "12")
        },
        compRightSM: function() {
            return this.widthFromString(this.sm||"12-12", 1, "12")
        },
        compRightLG: function() {
            var lg = (this.lg) ? this.lg : (this.$slots['right']  ? "6-6":"12-12" )
            return this.widthFromString(lg, 1, this.$slots['right'] ? "6" : "12")
        },

    }
}
</script>
