<template>

    <Page>
        <template v-slot:contenttopbarleft>
            <h1>Beveiliging</h1>
        </template>

        <v-row>
            <v-col lg="6" sm="12">
                <v-card class="content">
                    <v-card-title>
                        Wachtwoord
                    </v-card-title>
                    <v-card-text>
                        <v-divider class="mb-4"></v-divider>
                            <v-form>
                                <v-row dense>
                                    <v-col cols="6">
                                       <PasswordField disabled v-model="password"></PasswordField>
                                    </v-col>
                                    <v-col class="text-right" cols="6">
                                        <ActionLinkButton action="auth.changepassword"></ActionLinkButton>
                                    </v-col>
                                </v-row>            
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col lg="6" sm="12">
                <v-card class="content">
                    <v-card-title>
                        2FA (twee-factor authenticatie) - Aanbevolen
                    </v-card-title>
                    <v-card-text>
                        <div>
                            Het gebruik van twee-factor authenticatie helpt het risico op gegevensinbreuk te verminderen en de veiligheid van 
                            vertrouwelijke gegevens te minimaliseren. 
                        </div>
                        <v-divider class="my-4"></v-divider>
                            <v-list-item one-line >
                                <v-list-item-action class="mr-4">
                                    <v-avatar size="24"  dense rounded >
                                        <Icon color="primary" type="user"></Icon>
                                    </v-avatar>
                                </v-list-item-action>

                                <v-list-item-content>
                                    <v-list-item-title>Google Authenticator / Duo</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action class="mr-1">
                                        <ActionLinkButton v-if="user.two_factor" @click='onDisableTwofactor'>Uitschakelen</ActionLinkButton>
                                        <ActionLinkButton action="auth.setup2fa" no-execute @click="onSetupTwoFactor" v-else block main>Inschakelen</ActionLinkButton>
                                </v-list-item-action>
                            </v-list-item>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </Page>

</template>

<script>

import Page from '@/overrides/auth/user/Page.vue'
import user from '@app/user'

import Form from '@controls/forms/Form'
import TextField from '@controls/input/TextField'
import ActionButton from '@shared/ui/controls/buttons/ActionButton'
import PasswordField from '@controls/input/PasswordField'
import Checkbox from "@controls/check/Checkbox.vue";
import FormColumn from '@controls/forms/FormColumn'
import FormRow from '@controls/forms/FormRow'
import ActionLinkButton from '@controls/buttons/ActionLinkButton'
import Icon from '@controls/icons/Icon'
import eventbus from '@app/eventbus';


export default {
    name: 'Login',
    components: {
        Page, 
        Form, FormRow, FormColumn,
        TextField, PasswordField, Icon,
        ActionButton, Checkbox, ActionLinkButton
    }, 
    data: function() {
        return {
            email: "",
            password: "niet-zichtbaar",
            two_factor: false
        }
    },   
    methods: {
        onChangeUsername: function() {
        },
        onDisableTwofactor: function() {
            eventbus.dialog.confirm.password.promise()
                .then( async () => {
                    var result = await this.user.removeTwoFactor();
                    if (!result.success) {
                        eventbus.snackbar.error({text: result.message});
                    } else {
                        eventbus.snackbar.info({text: result.message});
                    }        
                })
        },
        onSetupTwoFactor: function() {
            eventbus.dialog.confirm.password.promise()
                    .then( () => {
                        eventbus.dialog.auth.setup2fa();
                    })
        },
        

    },
    mounted: function() {
    },
    setup() {
        return {
            user: user
        }
    }
}
</script>



