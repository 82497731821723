<template>    
    <v-row dense>
        <v-col :class="clsLabel">
            <label :class="subLabel?'label-top':''">{{label}}</label>
            <caption class="caption-sub" v-if="subLabel">{{subLabel}}</caption>            
        </v-col>
        <slot name="value">
            <v-col :class="clsValue">
                <slot></slot>
                <span class="text-small text-italic"><slot name="info"></slot></span>
            </v-col>
        </slot>
    </v-row>
</template>

<script>

//
// Usage: 
//
//   import FormRow from '@controls/forms/FormRow'
//   For further example, see Form.vue
//
export default {
    name: 'FormRow',
    components: {},
    props: {
        label: {
            type: [String],
            default: ""
        },
        subLabel: {
            type: [String],
            default: ""
        },
        colLabel: {
            type: [String, Number],
            default: "4"
        },
        colValueRight: {
            type: [Boolean],
            default: false
        }
    },
    computed: {
        clsLabel() {
            return `col-${Number(this.colLabel||4)}`; 
        },
        clsValue() {
            let cls = this.colValueRight ? 'text-right' : '';
            return `${cls} col-${12-Number(this.colLabel||4)}`; 
        },
    }
}
</script>
