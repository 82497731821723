<template>    
        <v-col :class="col" class="col py-0">                
            <slot></slot>
        </v-col>
</template>

<script>

//
// Usage: 
//
//   import FormColumn from '@controls/forms/FormColumn'
//   For further example, see Form.vue
//
export default {
    name: 'FormColumn',
    components: {},
    props: {
        col: {
            type: [String],
            default: ""
        }
    },
}
</script>
