<template>
      <Frame :showtopmenu="false" :showleftmenu="false">

        <template v-slot:title>
            Gebruikersprofiel
        </template>

        <template v-slot:contenttopbarleft v-if="$slots.contenttopbarleft">
            <slot name="contenttopbarleft"></slot>
        </template>
        
        <template v-slot:pagemenu>
            <slot name="pagemenu">
                <v-list dense>
                    <RouteMenuItem route="auth.profile">Profiel</RouteMenuItem> 
                    <RouteMenuItem route="auth.security">Beveiliging</RouteMenuItem> 
                </v-list>              
            </slot>
        </template>

        <template v-slot:content>
            <v-container style="height:100vh;" class="" fluid>
                <slot></slot>
            </v-container>
        </template>

      </Frame>
</template>

<script>

import Frame from '@/ui/main/Frame.vue'
import RouteMenuItem from "@controls/menu/RouteMenuItem.vue";

export default {
    name: 'UserPage',
    components: {
        RouteMenuItem, Frame
    }, 
}
</script>



