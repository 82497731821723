<template>    
    <v-switch v-if="switcher" 
        dense
        class="mt-0"
        v-model="dvalue"
        :label="label"
        :disabled="compDisabled||compSkeleton"
    ></v-switch>

    <v-checkbox v-else
        class="checkbox mt-0 pt-0"
        v-model="dvalue"
        hide-details 
        dense 
        :disabled="compDisabled||compSkeleton"
        :label="label">
        <template v-slot:append>
            <slot name="append"></slot>
        </template>
        <template v-slot:label>
            <slot name="label"></slot>
        </template>

    </v-checkbox>

</template>

<script setup>
//
// Usage: 
// import Checkbox from "@controls/check/Checkbox.vue";
// <Checkbox :model="model" v-model="valueIsSet"></Checkbox>
// or
//   <TextField :value="line.ihp_name"  @input="e => onSet('ihp_name', e)"></TextField>
//

import bool from '@lib/bool';

//
// Synchronisation from and to entities: 
//
// Entity --> checkbox
//   The entity model is bound via v-model="isValue"
//   The data is received in the value property. 
//   We can not use the value property as the model for the checkbox as it is 'illigal' to modify a property directly.
//   Therefore, the value in the 'value' property is transfered to datamember 'dvalue' on mount an on watch of value.
//   So, whenever the value in the model changes, the checkbox is updated.
// 
// Checkbox --> Entity
//   We ony want to transfer the value to the model on user interaction. 
//   For this reason, we do NOT transfer data via watcher on dvalue member back to the model.
//   Instead, we use the 'change' event from the control to sent the value back to the bound model.
//   
// We don't want to watch dvalue and send back the value on change to the model. 
// That is because the data would be sent back when valud changes. It goes via various watchers, which 
// makes the change asynchronous. This means that a model can get a changed event during filling of the data in the model.
// This data can be received during filling but - due to the asynchrosity - also after filling, which would be a 'fake' data change.
//
// Any way, long value short. Best practise is binding from value to control, and user change back to model.
//

import {computed, ref} from 'vue'
        
    const props = defineProps({
        disabled: {
            type: [Boolean]
        },
        skeleton: {
            type: [Boolean]
        },
        value: {
            type: [Boolean, Number]
        },
        model: {
            type: [Object]
        },
        switcher: {
            type: [Boolean],
            default: false
        },
        label: {},
    })

    const emit = defineEmits(['input'])
    const dvalue = computed({
        get() {
            return props.value
        },
        set: function(v) {
            emit('input', bool.isTrue(v));
            emit('click', bool.isTrue(v));
        }	
    })

    const compSkeleton = computed({
        get() {
            if (props.skeleton) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.isDataLoading;
        }
    });
    const compDisabled = computed({
        get() {
            if (props.disabled) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.disabled;
        }
    });


</script>
<style>

</style>