//
// Usage: 
//  import bool from '@shared/lib/bool'
//

var bool = {};
bool.isTrue = (val) => {
    if (!val) {
        return false;
    }
    return val && (Number(val) === 1 || val === true || val == "true" || val == "True");
};
bool.isNotTrue = (val) => {
    return !bool.isTrue(val);
};
bool.JaNee = (val) => {
    return bool.isTrue(val) ? "Ja" : "Nee";
};
bool.compare = (l,r) => {
    return bool.isTrue(l) == bool.isTrue(r);
}
export default bool;